import React, { useState } from 'react';

import Layout from '../components/Layout';

const About = () => {
  return (
    <Layout className="our-beliefs">
      <section>
        <img className="one" />
        <img className="two" />
      </section>
    </Layout >
  );
};

export default About;